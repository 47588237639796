import React, { useState, useEffect, useRef } from 'react';
import useApi from '../../api/useApi';
import { CareateCourse , GetCourse } from '../../api/urls';


function AddNewCourse({ modifyCourseID, onClose, reloadpage}) { 
  //
  const image = useRef();
  //
  const [selectedImg, setImage] = useState();
  const [base64Imgstate, setBase64Img] = useState();
  const [formData, setFormData] = useState({CourseID:"",PopupStr_start:"" ,PopupStr_end:"" ,coursePriceText:"",coursePriceAV:"",coursePriceAVassistant:"",courseCategory:'', courseName: '', courseDesc: '', courseShortdetails: '', imagedata:""
    ,coursebrief:"",academic_options:"",CareerObjectiveStr:"",course_forAgeGroup:"",course_target_exam:"" });

  const [ServerRes, uploadData] = useApi();
  const [modifyCourse, GetCourseData] = useApi();
  //
  useEffect(() => { // console.log(" -- modifyCourseID : ", modifyCourseID )
    if( modifyCourseID && modifyCourseID != "0" ) {
      GetCourseData(GetCourse, 'POST', {"CourseID" : modifyCourseID } );
    }
  }, [modifyCourseID]);

  useEffect(() => { 
    // console.log(" -- modifyCourse : ", modifyCourse ); 
    if( modifyCourse.data?.course ){ console.log(" -- form data set : " );   // CourseID:modifyCourse.data?.course.CourseID,
      setFormData({CourseID:modifyCourse.data?.course._id, PopupStr_start:modifyCourse.data?.course.PopupStr_start ,PopupStr_end:modifyCourse.data?.course.PopupStr_end 
        ,coursePriceText:modifyCourse.data?.course.coursePrice1,coursePriceAV:modifyCourse.data?.course.coursePrice2,coursePriceAVassistant:modifyCourse.data?.course.coursePrice3
        ,courseCategory:modifyCourse.data?.course.courseCategory, courseName:modifyCourse.data?.course.courseName, courseDesc:modifyCourse.data?.course.courseDesc, courseShortdetails:modifyCourse.data?.course.courseShortdetails, imagedata:modifyCourse.data?.course.imagedata,coursebrief:modifyCourse.data?.course.coursebrief,academic_options:modifyCourse.data?.course.academic_options,CareerObjectiveStr:modifyCourse.data?.course.CareerObjectiveStr ,course_forAgeGroup:modifyCourse.data?.course.forAge
      ,course_target_exam:modifyCourse.data?.course.course_target_exam  });
      setBase64Img(modifyCourse.data?.course.imagedata) ;
    } 
  }, [modifyCourse]);

  useEffect(() => {
    if (selectedImg) {
      const reader = new FileReader();
      reader.readAsDataURL(selectedImg);
      reader.onloadend = async () => {
        const base64Image = reader.result.split(',')[1]; // Extract base64 part
        // setShowLoading(true);
        // Send base64 image to your Node.js server
        // 
        setBase64Img( "data:image/jpeg;base64, " + base64Image);
        // console.log(" base64Image : ", base64Image )
      };
    }
  }, [selectedImg]);
  useEffect(() => { 
    // console.log("Save Course data", ServerRes )
    if (!ServerRes.isLoading && !ServerRes.isError && ServerRes.data?.noError) {
      alert('Data Saved Successfully.');  
      clearInputs(); 
      reloadpage();
    }
    if (!ServerRes.isLoading && ( ServerRes.isError || ServerRes.data?.Error) ) {
      alert('Failed to Save Course details');  
    }
  }, [ServerRes]);

  const clearInputs = () => {
    image.current.value = null;
    setBase64Img(null) ;
    setFormData({CourseID:"",PopupStr_start:"" ,PopupStr_end:"" ,coursePriceText:"",coursePriceAV:"",coursePriceAVassistant:"",courseCategory:'', courseName: '', courseDesc: '', courseShortdetails: '', imagedata:""
      ,coursebrief:"",academic_options:"",CareerObjectiveStr:"",course_forAgeGroup:"",course_target_exam:"" });
  }
  const handleInputChange = (e) => {
    if( (e.target.name == 'coursePriceText'|| e.target.name == 'coursePriceAV' || e.target.name == 'coursePriceAVassistant' ) && e.target.value.length > 5 ){ return  }
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const Validations = () => {   
    let checkDetRows = formData.courseShortdetails ? formData.courseShortdetails.split('\n') : [] ; 
    let showMsg = "" ; 
    if( !formData.courseCategory || formData.courseCategory == "" ){ showMsg = showMsg + "Please enter course category.\n" ; }
    if( !formData.courseName || formData.courseName == "" ){ showMsg = showMsg + "Please enter course name.\n" ; }
    if( !formData.course_target_exam || formData.course_target_exam == "" ){ showMsg = showMsg + "Please enter target exam name.\n" ; }
    if( !formData.course_forAgeGroup || formData.course_forAgeGroup == "" ){ showMsg = showMsg + "Please enter course Age group (Used in AI).\n" ; }
    if( !formData.coursePriceText || formData.coursePriceText == "" ){ showMsg = showMsg + "Please enter course Price for text based study.\n" ; }
    if( !formData.coursePriceAV || formData.coursePriceAV == "" ){ showMsg = showMsg + "Please enter course for audio video based study.\n" ; }
    if( !formData.coursePriceAVassistant || formData.coursePriceAVassistant == "" ){ showMsg = showMsg + "Please enter course Price for Audio video and AI Assistant Based study.\n" ; }
    if( !formData.courseDesc || formData.courseDesc == "" ){ showMsg = showMsg + "Please enter course description.\n" ; }
    if( !base64Imgstate || base64Imgstate == "" ){ showMsg = showMsg + "Please select image for course.\n" ; }
    //
    if( checkDetRows.length < 1 ){ showMsg = showMsg + "Please enter details to show on hover.\n" ; }
    else if( checkDetRows.length > 3 ){ showMsg = showMsg + "We can only show maximum of 3 lines on hover.\n" ; }
    else{
      let LineNoStr = "" ;
      for(let index1 = 0 ; index1 < checkDetRows.length ; index1++ ){
        if(checkDetRows[index1].length > 30 ){
          LineNoStr = LineNoStr + ( index1 + 1 ) + ","
        }
      }
      if( LineNoStr != "" ){ 
        LineNoStr = LineNoStr.substring(0, LineNoStr.length - 1)
        showMsg = showMsg + "Line No. " + LineNoStr +" has more then 30 characters to show in details on hover.\n" ;
      }
    } 
    if( !formData.PopupStr_start || formData.PopupStr_start == "" ){ showMsg = showMsg + "Please enter course detail popup info.(start)\n" ; }
    if( !formData.PopupStr_end || formData.PopupStr_end == "" ){ showMsg = showMsg + "Please enter course detail popup info.(end)\n" ; }
    return showMsg ;
  }
  const handleSubmit = (e) => {
    e.preventDefault(); 
    // console.log("form data to upload : ", formData )  
    let anyMsg = Validations();
    if(anyMsg){ alert(anyMsg) ; }
    else{
      let ReqData = {...formData , imagedata : base64Imgstate }  
      if(ReqData.coursePriceText){ } else { ReqData.coursePriceText = "99999"; }
      if(ReqData.coursePriceAV){ } else { ReqData.coursePriceAV = "99999"; }
      if(ReqData.coursePriceAVassistant){ } else { ReqData.coursePriceAVassistant = "99999"; }
      if(ReqData.course_forAgeGroup){ } else { ReqData.course_forAgeGroup = ""; }
      if(ReqData.courseCategory){ } else { ReqData.courseCategory = ""; }
      if(ReqData.courseName){ } else { ReqData.courseName = ""; }
      if(ReqData.courseDesc){ } else { ReqData.courseDesc = ""; }
      if(ReqData.course_target_exam){ } else { ReqData.course_target_exam = ""; }
      if(ReqData.courseShortdetails){ } else { ReqData.courseShortdetails = ""; }
      if(ReqData.coursebrief){ } else { ReqData.coursebrief = ""; }
      if(ReqData.academic_options){ } else { ReqData.academic_options = ""; }
      if(ReqData.CareerObjectiveStr){ } else { ReqData.CareerObjectiveStr = ""; } 
      if(ReqData.imagedata){ } else { ReqData.imagedata = ""; }
      if(ReqData.PopupStr_start){ } else { ReqData.PopupStr_start = ""; }
      if(ReqData.PopupStr_end){ } else { ReqData.PopupStr_end = ""; }
      if(ReqData.CourseID){ } else { ReqData.CourseID = "" ; }
      // console.log("ReqData : ", ReqData ) 
      uploadData(CareateCourse, 'POST', ReqData,null);
    }
  } 

  return (
    <div
      className="flex justify-center items-center h-full w-full fixed left-0 top-0 z-30 pt-420px overflow-y-auto"
      style={{ background: 'linear-gradient(rgba(0, 0, 0, 0.5),   rgba(0, 0, 0, 0.5))' }}
    >
      <div className="bg-white w-10/12 mt-52 rounded-2xl relative">
        <span className="block font-bold py-4 px-6" style={{ borderBottom: '0.71px solid #E7E700' }}>
          Add Course
        </span>
        <img
          src="assets/img/Close.svg"
          className="absolute top-4 mt-1 right-4 h-3 cursor-pointer"
          alt=""
          onClick={() => onClose()}
        />
        <form className="mt-2 mb-4 mx-4 flex flex-col px-2" autoComplete="off" onSubmit={handleSubmit}>
          <div className='flex flex-row justify-between' > 
            <div className='w-5/12'>
              <div className="mt-2 mb-4 w-full relative font-semibold">
                <label htmlFor="category" className="block text-parameter text-sm">
                  Course Category
                </label>
                <input
                  type="text"
                  placeholder="Course Category"
                  autoComplete="off"
                  value={formData.courseCategory}
                  name="courseCategory"
                  onChange={handleInputChange}
                  className="w-full text-xs rounded-3xl border px-4 mt-2 py-2.5 focus:outline-none"
                />
              </div>
            </div> 
            <div className='w-5/12'>
              <div className="mt-2 mb-4 w-full relative font-semibold">
                <label htmlFor="category" className="block text-parameter text-sm">
                  Course Name
                </label>
                <input
                  type="text"
                  placeholder="Course Name"
                  autoComplete="off"
                  value={formData.courseName}
                  name="courseName"
                  onChange={handleInputChange}
                  className="w-full text-xs rounded-3xl border px-4 mt-2 py-2.5 focus:outline-none"
                />
              </div>
            </div> 
          </div>
          <div className="mt-2 mb-4 w-full relative font-semibold">
            <label htmlFor="category" className="block text-parameter text-sm">
              Course Short Description
            </label>
            <input
              type="text"
              placeholder="Details of syllabus"
              autoComplete="off"
              value={formData.courseDesc}
              // onChange={(e) => setClass(e.target.value)}
              name="courseDesc"
              onChange={handleInputChange}
              className="w-full text-xs rounded-3xl border px-4 mt-2 py-2.5 focus:outline-none"
            />
          </div>
          <div className="mt-2 mb-4 w-full relative font-semibold">
            <div className="grid grid-cols-5 gap-4"> 
              <div className='' >
                  <label htmlFor="category" className="block text-parameter text-sm">
                    Course Price Text Based
                  </label>
                  <input
                    type="number"
                    placeholder="Price"
                    autoComplete="off"
                    value={formData.coursePriceText}
                    // onChange={(e) => setClass(e.target.value)}
                    name="coursePriceText"
                    onChange={handleInputChange}   
                    className="w-full text-xs rounded-3xl border px-4 mt-2 py-2.5 focus:outline-none"
                  />
                </div>
                <div className='' >
                  <label htmlFor="category" className="block text-parameter text-sm">
                    Course Price Audio Video Based
                  </label>
                  <input
                    type="number"
                    placeholder="Price"
                    autoComplete="off"
                    value={formData.coursePriceAV}
                    // onChange={(e) => setClass(e.target.value)}
                    name="coursePriceAV"
                    onChange={handleInputChange}   
                    className="w-full text-xs rounded-3xl border px-4 mt-2 py-2.5 focus:outline-none"
                  />
                </div>
                <div className='' >
                  <label htmlFor="category" className="block text-parameter text-sm">
                    Course Price AV and Assistant
                  </label>
                  <input
                    type="number"
                    placeholder="Price"
                    autoComplete="off"
                    value={formData.coursePriceAVassistant}
                    // onChange={(e) => setClass(e.target.value)}
                    name="coursePriceAVassistant"
                    onChange={handleInputChange}   
                    className="w-full text-xs rounded-3xl border px-4 mt-2 py-2.5 focus:outline-none"
                  />
                </div>
              </div>
            </div>
            <div className="mt-2 mb-4 w-full relative font-semibold"> 
            <div className="grid grid-cols-5 gap-4">
            <div className='' >
              <label htmlFor="category" className="block text-parameter text-sm">
                Target Exam Name
              </label>
              <input
                type="text"
                placeholder="Target Exam Name"
                autoComplete="off"
                value={formData.course_target_exam}
                // onChange={(e) => setClass(e.target.value)}
                name="course_target_exam"
                onChange={handleInputChange}   
                className="w-full text-xs rounded-3xl border px-4 mt-2 py-2.5 focus:outline-none"
              />
            </div> 
            <div className='' >
              <label htmlFor="category" className="block text-parameter text-sm">
                Course For Age Group 
              </label>
              <input
                type="number"
                placeholder="Age Group"
                autoComplete="off"
                value={formData.course_forAgeGroup}
                // onChange={(e) => setClass(e.target.value)}
                name="course_forAgeGroup"
                onChange={handleInputChange}   
                className="w-full text-xs rounded-3xl border px-4 mt-2 py-2.5 focus:outline-none"
              />
            </div> 
            <div className='' >
              <label htmlFor="category" className="block text-parameter text-sm">
                Course Image
              </label>
              <input hidden ref={image} type="file" onChange={(e) => setImage(e.target.files[0])} />
              <button
                className="border w-36 rounded-3xl text-xs font-bold focus:outline-none text-center h-9 bg-primary mt-2 text-white"
                onClick={(e) => {
                  e.preventDefault();
                  image.current.click();
                }}
              >
                Upload Image
              </button> 
            </div>
            <div>
              {base64Imgstate && (<img className="h-24 w-auto mx-auto" src={base64Imgstate} width={50} height={50} ></img>) }
            </div>  
            </div>
          </div>
          <div className="mt-2 mb-4 w-full relative font-semibold overflow-hidden">
            <label htmlFor="category" className="block text-parameter text-sm">
              OnHover details of 4 line with max 30 characters per line
            </label>
            <textarea
              value={formData.courseShortdetails}
              rows="3"
              cols="50"
              // onChange={(e) => setGenNotes(e.target.value)}
              name="courseShortdetails"
              onChange={handleInputChange}
              placeholder=""
              className="w-full rounded-3xl border text-xs border-borderLight px-4 py-2 mt-2 focus:outline-none"
            />
          </div>
          <div className="mt-2 mb-4 w-full relative font-semibold overflow-hidden">
            <label htmlFor="category" className="block text-parameter text-sm">
              Course Brief
            </label>
            <textarea
              value={formData.coursebrief}
              rows="4"
              cols="50"
              // onChange={(e) => setGenNotes(e.target.value)}
              name="coursebrief"
              onChange={handleInputChange}
              placeholder=""
              className="w-full rounded-3xl border text-xs border-borderLight px-4 py-2 mt-2 focus:outline-none"
            />
          </div>
          <div className="mt-2 mb-4 w-full relative font-semibold overflow-hidden">
            <label htmlFor="category" className="block text-parameter text-sm">
              {'Academic options (one option in one line)'}
            </label>
            <textarea
              value={formData.academic_options}
              rows="4"
              cols="50"
              // onChange={(e) => setGenNotes(e.target.value)}
              name="academic_options"
              onChange={handleInputChange}
              placeholder={"Option 1 \nOption 2 \nOption 3"}
              className="w-full rounded-3xl border text-xs border-borderLight px-4 py-2 mt-2 focus:outline-none"
            />
          </div>
          <div className="mt-2 mb-4 w-full relative font-semibold overflow-hidden">
            <label htmlFor="category" className="block text-parameter text-sm">
              {'Career Objectives (one option in one line)'}
            </label>
            <textarea
              value={formData.CareerObjectiveStr} 
              rows="4"
              cols="50"
              // onChange={(e) => setGenNotes(e.target.value)}
              name="CareerObjectiveStr"
              onChange={handleInputChange}
              placeholder={"Option 1 \nOption 2 \nOption 3"}
              className="w-full rounded-3xl border text-xs border-borderLight px-4 py-2 mt-2 focus:outline-none"
            />
          </div>
          <div className="mt-2 mb-4 w-full relative font-semibold overflow-hidden">
            <label htmlFor="category" className="block text-parameter text-sm">
              {'course detail popup info. (start)'}
            </label>
            <textarea
              value={formData.PopupStr_start}
              rows="4"
              cols="50"
              // onChange={(e) => setGenNotes(e.target.value)}
              name="PopupStr_start"
              onChange={handleInputChange}
              placeholder="first paragraph here \n first line \n 2nd line \n 3rd line"
              className="w-full rounded-3xl border text-xs border-borderLight px-4 py-2 mt-2 focus:outline-none"
            />
          </div>
          <div className="mt-2 mb-4 w-full relative font-semibold overflow-hidden">
            <label htmlFor="category" className="block text-parameter text-sm">
            {'course detail popup info. (end)'}
            </label>
            <textarea
              value={formData.PopupStr_end}
              rows="4"
              cols="50"
              // onChange={(e) => setGenNotes(e.target.value)}
              name="PopupStr_end"
              onChange={handleInputChange}
              placeholder="last paragraph here"
              className="w-full rounded-3xl border text-xs border-borderLight px-4 py-2 mt-2 focus:outline-none"
            />
          </div>

          <div className="flex justify-end">
            {ServerRes.isLoading ? (
              <img src="assets/img/loader.svg" className="w-14 ml-auto" alt="" />
            ) : (
              <button
                type="submit"
                className="mt-1 lg:mt-0 w-auto lg:w-max border px-12 py-4 rounded-3xl text-xs font-bold focus:outline-none flex justify-center items-center h-7 bg-primary text-white"
                // onClick={(e) => {
                //   e.preventDefault(); 
                // }}
              >
                Save
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
}

export default AddNewCourse;
